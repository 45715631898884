<template>
  <v-container
    fluid
    tag="section"
  >
    <v-tabs v-model="step" center-active grow id="inputform">
      <v-tab href="#step1" v-if="isMaster || isStbWWk(id)">Wettkampfeinstellungen</v-tab>
      <v-tab href="#step2">Mannschaften</v-tab>
      <v-tab href="#step3">Downloads</v-tab>
      <v-tab href="#step4" v-if="isMaster || isStbWWk(id)">Ergebnisse</v-tab>
      <v-tab href="#step5" v-if="isMaster || isStbWWk(id)">Abschluss</v-tab>
      <v-tab href="#step6" v-if="isMaster || isStbWWk(id)">Zwischenstand</v-tab>
      <v-tab href="#step7" v-if="isMaster || isStbWWk(id)">Kontrollansicht</v-tab>

      <v-btn
        fab
        text
        small
        @click="fullscreen"
      >
        <v-icon v-if="!isFullscreen">
          far fa-expand-wide
        </v-icon>
        <v-icon v-else>
          far fa-compress-wide
        </v-icon>
      </v-btn>

      <v-tab-item id="step1"><h1>Wettkampfeinstellungen</h1><allgemein :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step2"><h1>Mannschaften</h1><mannschaften :id="id" :e="e" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step3"><h1>Downloads</h1><downloads :id="id" :e="e" :df="DisciplineFind" :r="ResultFind" /></v-tab-item>
      <v-tab-item id="step4"><h1>Ergebnisse</h1><ergebnisse :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" :f="isFullscreen" /></v-tab-item>
      <v-tab-item id="step5"><h1>Abschluss</h1><abschliessen :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step6"><h1>Zwischenstand</h1><zwischenstand :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
      <v-tab-item id="step7"><h1>Kontrollansicht</h1><kontrolle :id="id" :e="e" :r="ResultFind" :df="DisciplineFind" /></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'
import { query, resultquery } from '@/views/components/stb_w_2023/content/input/query'
import { mapGetters } from 'vuex'

export default {
  name: 'liveinput',

  components: {
    Allgemein: () => import('./input/allgemein'),
    Mannschaften: () => import('./input/mannschaften'),
    Downloads: () => import('./input/downloads'),
    Ergebnisse: () => import('./input/ergebnisse'),
    Zwischenstand: () => import('./input/zwischenstand'),
    Abschliessen: () => import('./input/abschliessen'),
    Kontrolle: () => import('./input/kontrolle')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    step: 1,
    Event: {},
    DisciplineFind: [],
    ResultFind: [],
    isFullscreen: false
  }),

  computed: {
    ...mapGetters(['isMaster', 'isStbWWk']),
    e () {
      return {
        ...this.Event,
        teams: this.Event?.teams?.map(t => ({
          ...t,
          athletes: t.athletes?.map(a => ({
            ...a,
            results: this.ResultFind.filter(r => r._team === t.team._id && r._person === a.person._id)
          }))
        })) || []
      }
    }
  },

  methods: {
    fullscreen () {
      this.$fullscreen.toggle(this.$el.querySelector('#inputform'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (f) {
      this.isFullscreen = f
    }
  },

  apollo: {
    Event: {
      query: gql`query($id: UUID!) {
        Event(id: $id) { ${query} }
      }`,
      variables () {
        return {
          id: this.id
        }
      }
    },
    DisciplineFind: {
      query: gql`query {
        DisciplineFind { _id name shortName regular }
      }`,
      fetchPolicy: 'cache-first',
      subscribeToMore: {
        document: gql`subscription {
          DisciplineCreate { _id name shortName regular }
        }`,
        updateQuery: updateQuery('DisciplineFind', 'DisciplineCreate')
      }
    },
    ResultFind: {
      query: gql`query($type: ResultType!, $event: UUID!) {
        ResultFind(type: $type, event: $event) { ${resultquery} }
      }`,
      variables () {
        return {
          type: 'stb_w_2023_wk_team_athlete',
          event: this.id
        }
      },
      subscribeToMore: {
        document: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultCreate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            type: 'stb_w_2023_wk_team_athlete',
            event: this.id
          }
        },
        updateQuery: updateQuery('ResultFind', 'ResultCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`subscription($id: UUID!) {
          EventUpdate(id: $id) { ${query} }
        }`,
        variables () {
          return {
            id: this.id
          }
        }
      },
      ResultUpdate: {
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultUpdate(type: $type, event: $event) { ${resultquery} }
        }`,
        variables () {
          return {
            type: 'stb_w_2023_wk_team_athlete',
            event: this.id
          }
        }
      },
      ResultDelete: {
        query: gql`subscription($type: ResultType!, $event: UUID!) {
          ResultDelete(type: $type, event: $event)
        }`,
        variables () {
          return {
            type: 'stb_w_2023_wk_team_athlete',
            event: this.id
          }
        },
        result (id) {
          deleteQuery('ResultFind', 'ResultDelete', this.ResultFind, id)
          console.log(this.ResultFind.find(r => r._id === id.data.ResultDelete))
          setTimeout(() => {
            console.log(this.ResultFind.find(r => r._id === id.data.ResultDelete))
          }, 1000)
        }
      },
      DisciplineUpdate: {
        query: gql`subscription {
          DisciplineUpdate { _id name shortName regular }
        }`
      },
      DiscliplineDelete: {
        query: gql`subscription {
          DisciplineDelete
        }`,
        result (id) {
          deleteQuery('DisciplineFind', 'DisciplineDelete', this.DisciplineFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>
h1 {
  background: rgb(80, 80, 80);
  color: white;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
}
</style>
