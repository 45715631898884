export const query = `
  _id
  _parent
  type
  ... on StbW2023Wk {
    name
    parent {
      _id
      ... on StbW2023League {
        name
        parent {
          _id type
          ... on StbW2023 { name startDate }
        }
      }
    }
    status
    mode
    startDate
    venue { _id name address { streetAddress streetNumber postalCode addressLocality } }
    disciplines { _discipline order }
    judges { type person { _id givenName familyName } _discipline }
    teams {
      team { _id name }
      _startdiscipline order
      trainer { person { _id givenName familyName } order }
      results(discipline: null) { _id type penalty final tp }
      athletes {
        person { _id givenName familyName }
        order
      }
    }
    topathletes {
      team { _id name }
      person { _id givenName familyName }
      disciplines
      final
    }
    acl {
      _id familyName givenName
    }
  }
`

export const resultquery = `
  _id
  type
  ... on StbW2023WkTeamAthleteResult {
    _team _person _discipline
    person { _id givenName familyName }
    team { _id name }
    subpart order
    dscore escore
    e1 e2 e3 e4 deduction
    penalty
    final
  }
`
